import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Footer from "../components/Shared/Footer/Footer";
import LinearChart from "../components/Chart/LinearChart";
import SecurityFooter from "../components/SecurityPage/SecurityFooter/SecurityFooter";
import SecurityTableShort from "../components/Security/SecurityTableShort";
import { formatPercentage } from "../lib/utilities";
import { getTrendIcon } from "../lib/utilitiesComponent";
import BigHeader from "../components/Shared/Header/BigHeader";
import { HashLink } from "react-router-hash-link";

const Security = () => {
  const { strategyCode } = useParams();
  const { sectorCode } = useParams();
  const { securityCode } = useParams();
  const [securities, setSecurities] = useState([]);
  const [strategy, setStrategy] = useState({});
  const [security, setSecurity] = useState([]);

  useEffect(() => {
    let cache =
      JSON.parse(sessionStorage.getItem(strategyCode)) ?? JSON.stringify([]);
    if (cache) {
      setStrategy(cache);
    }

    getApi("strategy_details", { strategy: strategyCode }).then((r) => {
      setStrategy(r["info"]);
      sessionStorage.setItem(
        strategyCode + "_strategy",
        JSON.stringify(r["info"])
      );
    });
  }, [strategyCode]);

  useEffect(() => {
    const strategy = JSON.parse(
      sessionStorage.getItem(strategyCode + "_strategy")
    );

    const securities = JSON.parse(
      sessionStorage.getItem(strategyCode + "_" + sectorCode + "_security_list")
    );

    const security = JSON.parse(
      sessionStorage.getItem(
        strategyCode + "_" + securityCode + "_security_details"
      )
    );

    if (strategy) {
      setStrategy(strategy);
    }

    if (securities) {
      setSecurities(securities);
    }

    if (security) {
      setSecurity(security);
    }

    getApi("security_list", {
      strategy: strategyCode,
      sector: sectorCode,
    }).then((r) => {
      setSecurities(r);
      sessionStorage.setItem(
        strategyCode + "_" + sectorCode + "_security_list",
        JSON.stringify(r)
      );
    });

    getApi("security_details", {
      strategy: strategyCode,
      security: securityCode,
    }).then((r) => {
      setSecurity(r);
      sessionStorage.setItem(
        strategyCode + "_" + securityCode + "_security_details",
        JSON.stringify(r)
      );
    });
  }, [strategyCode, sectorCode, securityCode]);

  return (
    <>
      <BigHeader strategy={strategy} />
      {strategy["color"] ? (
        <>
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to={"/"}>Index</Link>
              </li>
              <li>
                <Link to={`/strategy/${strategyCode}`}>{strategy["name"]}</Link>
              </li>
              <li>
                <Link to={`/strategy/${strategyCode}/${sectorCode}`}>
                  {sectorCode}
                </Link>
              </li>
              <li>{securityCode}</li>
            </ul>
          </section>
        </>
      ) : null}

      {strategy["color"] ? (
        <div
          className="sectorTitle"
          style={{ backgroundColor: `${strategy["color"]}` }}
        >
          {securityCode}
        </div>
      ) : null}

      {security["historical"] ? (
        <section className="cumulated-return">
          <div className="graph-container">
            <h2 className="">
              Shared price vs Druid Strategy
              <sup>
                <HashLink smooth to="#legenda">
                  4
                </HashLink>
              </sup>
            </h2>
            <LinearChart
              data={security["historical"]}
              currency={security["security"]["currency"]}
              xAxisKey="end"
              pointKey="share_price"
              colorLine="#163470"
              secondLine="druid_strategy_adj"
              colorSecondLine="#459AD6"
              legend={true}
              tooltip={["signal"]}
              hasFilter={false}
            />
            <SecurityFooter security={security["security"]} />
          </div>
          <div className="sidebar">
            <div className="block-module list-table">
              <h4>Market data</h4>
              <ul>
                <li>
                  <p>Exchange</p>
                  <p>{security["security"]["exchange"]}</p>
                </li>
                <li>
                  <p>Select Currency</p>
                  <p>{security["security"]["currency"]}</p>
                </li>
                <li>
                  <p>Share Price</p>
                  <p>{security["security"]["share_price"]}</p>
                </li>
                <li>
                  <p>Market Cap</p>
                  <p>{security["security"]["market_cap"]}</p>
                </li>
                <li>
                  <p>Average 3M Daily volume</p>
                  <p>{security["security"]["average_3m_daily_volume"]}</p>
                </li>
              </ul>
            </div>
            <div className="sector-module">
              <h4>Druid sector selected comparables</h4>
              {security["related"] ? (
                <ul>
                  {security["related"].map((value, i) => (
                    <li key={i}>
                      <div>
                        <Link
                          reloadDocument
                          to={`/strategy/${strategyCode}/${sectorCode}/${value["symbol"]}`}
                        >
                          <span className="symbol">{value["symbol"]}</span>
                          <span className="name">{value["name"]}</span>
                        </Link>
                      </div>
                      <div className="signalCell">
                        <span>{value["signal"]}</span>
                      </div>
                      <div className="scoreCell">
                        <span>{formatPercentage(value["score"])}</span>
                        <span className="trend">
                          {getTrendIcon(value["score_trend"])}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ) : (
        <div className="animate-pulse bodyPlaceholder">
          <h2>
            <span className="loader">Loading </span>
            <p>{securityCode}</p>
          </h2>
        </div>
      )}

      {securities ? (
        <SecurityTableShort
          securityList={securities}
          sectorCode={sectorCode}
          strategyCode={strategyCode}
        />
      ) : (
        ""
      )}

      <section id="legenda">
        <p className="label">
          Here we provide a legend explaining the meaning of the data presented
        </p>
        <div className="note-content">
          {strategy ? (
            <div className="note">
              <b>1. {strategy["name"]}</b>
              <p>
                The execution of this strategy follows these parameters: entry
                and exit for each security occur at the open price unless a stop
                loss equal to 3% from the average purchase price is triggered.
                In the case of open gaps exceeding 3%, the trade is entirely or
                partially skipped. Druid Vision remains available to analyze,
                implement, and optimize both discretionary and systematic
                execution.
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="note">
            <b>2. Active Stocks</b>
            <p>
              An active stock is one where a group of AI-enhanced algorithms has
              consistently identified patterns that can be positively leveraged
              for performance. This graph shows the trend in the proportion of
              active stocks within the total reference universe, highlighting
              how the number of stocks with exploitable patterns has evolved
              over time
            </p>
          </div>
          <div className="note">
            <b>3. Drawdown risk</b>
            <p>
              Drawdown risk represents the potential decline in investment value
              from its peak to a lower point. Our goal is to avoid significant
              drawdowns, aiming to maintain stability in returns. The trend in
              active stocks can impact this risk level, ranging from very low to
              extreme. By carefully analyzing active stock patterns, we can
              manage and mitigate exposure to higher drawdown risks.
            </p>
          </div>
          <div className="note">
            <b>4. Strategy</b>
            <p>
              Share Price vs Druid Strategy compares the individual stock’s
              price trend against the performance of the Druid strategy applied
              to that stock, following its signals over the past two years. This
              comparison illustrates how the strategy has tracked and responded
              to market movements relative to the stock’s actual price behavior.
            </p>
          </div>
          <div className="note">
            <b>5. Score</b>
            <p>
              The Score represents an effectiveness level, ranging from 0 to
              100%, of the best AI-powered algorithm selected for a given stock.
              The Driver refers to the specific market condition that the
              algorithm identifies and exploits to achieve optimal performance.
            </p>
          </div>
          <div className="note">
            <b>6. MA, Slope, R²</b>
            <p>
              Short Term MA and Long Term MA refer to the moving averages of the
              Druid strategy over shorter and longer timeframes, respectively,
              providing insights into recent versus broader trends. Slope
              indicates the gradient of a linear regression model applied to the
              Druid strategy, reflecting the trend’s direction and strength,
              while R² measures the model’s goodness of fit, showing how well
              the strategy’s data aligns with the trendline.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Security;
