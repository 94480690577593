import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Footer from "../components/Shared/Footer/Footer";
import LinearChart from "../components/Chart/LinearChart";
import SecurityFooter from "../components/SecurityPage/SecurityFooter/SecurityFooter";
import SectorsList from "../components/SectorsList/SectorsList";
import BigHeader from "../components/Shared/Header/BigHeader";
import Power from "../images/icons/accensione.svg";

const Strategy = () => {
  const { strategyCode } = useParams();
  const [strategy, setStrategy] = useState({});
  const [sectors, setSectors] = useState([]);
  const [years, setYears] = useState("all");

  useEffect(() => {
    const strategia = sessionStorage.getItem(strategyCode + "_strategy");
    if (strategia !== "undefined") {
      const strategy = JSON.parse(strategia);
      setStrategy(strategy);
    }

    const listaSettori = sessionStorage.getItem(strategyCode + "_sector_list");
    if (listaSettori !== "undefined") {
      const sectors = JSON.parse(listaSettori);
      setSectors(sectors);
    }

    getApi("strategy_details", { strategy: strategyCode }).then((r) => {
      setStrategy(r["info"]);
      sessionStorage.setItem(
        strategyCode + "_strategy",
        JSON.stringify(r["info"])
      );
    });

    getApi("sector_list", { strategy: strategyCode }).then((r) => {
      setSectors(r);
      sessionStorage.setItem(strategyCode + "_sector_list", JSON.stringify(r));
    });
  }, [strategyCode]);

  useEffect(() => {
    if (
      strategy &&
      strategy["cumulated_returns"] &&
      strategy["cumulated_returns"]["forward"].length > 0
    ) {
      setYears("forward");
    }
  }, [strategy]);
  return (
    <>
      <BigHeader strategy={strategy} />
      {strategy && strategy["color"] ? (
        <>
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to={"/"}>Index</Link>
              </li>
              <li>{strategy["name"]}</li>
            </ul>
          </section>

          <section className="cumulated-return">
            <div className="graph-container">
              <h2>Cumulated Return</h2>
              <LinearChart
                data={strategy["cumulated_returns"]}
                xAxisKey="end_date"
                pointKey="value"
                colorLine="#163470"
                legend={true}
                tooltip={["end_date", "drawdown_risk", "value"]}
                hasFilter={true}
                setYears={setYears}
              />
            </div>
            <aside className="sidebar">
              <SecurityFooter
                footer={{
                  cagr: strategy["cagr"],
                  max_drawdown: strategy["max_drawdown"],
                }}
                years={years}
              />
            </aside>
          </section>
        </>
      ) : null}
      {sectors && sectors.length > 0 ? (
        <SectorsList strategyCode={strategyCode} sectors={sectors} />
      ) : (
        <div className="animate-pulse bodyPlaceholder">
          <h2>
            <span className="loader">Loading</span>
            <p> {strategyCode}</p>
          </h2>
        </div>
      )}

      <section id="legenda">
        <p className="label">
          Here we provide a legend explaining the meaning of the data presented
        </p>
        <div className="note-content">
          {strategy ? (
            <div className="note">
              <b>1. {strategy["name"]}</b>
              <p>
                The execution of this strategy follows these parameters: entry
                and exit for each security occur at the open price unless a stop
                loss equal to 3% from the average purchase price is triggered.
                In the case of open gaps exceeding 3%, the trade is entirely or
                partially skipped. Druid Vision remains available to analyze,
                implement, and optimize both discretionary and systematic
                execution.
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="note">
            <b>2. Active Stocks</b>
            <p>
              An active stock is one where a group of AI-enhanced algorithms has
              consistently identified patterns that can be positively leveraged
              for performance. This graph shows the trend in the proportion of
              active stocks within the total reference universe, highlighting
              how the number of stocks with exploitable patterns has evolved
              over time
            </p>
          </div>
          <div className="note">
            <b>3. Drawdown risk</b>
            <p>
              Drawdown risk represents the potential decline in investment value
              from its peak to a lower point. Our goal is to avoid significant
              drawdowns, aiming to maintain stability in returns. The trend in
              active stocks can impact this risk level, ranging from very low to
              extreme. By carefully analyzing active stock patterns, we can
              manage and mitigate exposure to higher drawdown risks.
            </p>
          </div>
          <div className="note">
            <b>4. Show history for</b>
            <p>
              The labels 1Y, 3Y, and Max represent backtesting periods,
              indicating performance analysis over one year, three years, and
              the maximum available timeframe. The label{" "}
              <img
                style={{ width: "10px", display: "inline" }}
                src={Power}
                alt="Forward"
              />{" "}
              refers to forward testing, which evaluates performance on unseen
              future data.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Strategy;
