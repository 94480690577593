import ActiveStockLineChart from "../Chart/ActiveStockLineChart";
import PieChartSecurity from "../Chart/PieChart";
import { HashLink } from "react-router-hash-link";

const BarGraph = ({ data, risk }) => {
  return (
    <div className="block-module line-graph">
      <h2>
        Active Stocks
        <sup>
          <HashLink smooth to="#legenda">
            2
          </HashLink>
        </sup>
      </h2>
      <div className="graph-container">
        <ActiveStockLineChart data={data["active_securities"]} />
        <PieChartSecurity data={data} risk={risk} />
      </div>
    </div>
  );
};

export default BarGraph;
