import styles from "./hpcard.module.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const HPCard = ({ title, description, color, link, drawdown_risk }) => {
  const [risk, setRisk] = useState(false);

  useEffect(() => {
    if (drawdown_risk > 1) {
      setRisk(false);
    } else {
      setRisk(true);
    }
  }, [drawdown_risk]);

  return (
    <Link
      to={`${link}`}
      className={`xl:col-span-5 lg:col-span-6 grid col-span-12 xl-:mb-32 mb-0 pb-8 ${styles.card}`}
      style={{ borderColor: `${risk ? "#FF0000" : color}` }}
    >
      <div
        className={styles.innerCard}
        style={{ borderColor: `${risk ? "#FF0000" : color}` }}
      >
        <h2
          className={styles.cardTitle}
          style={{ color: `${risk ? "#CACACA" : "#214EEF"}` }}
        >
          {title}
        </h2>
        <p className={styles.cardDescription}>{description}</p>
      </div>
    </Link>
  );
};

export default HPCard;
