const ListTable = ({ data, risk }) => {
  return (
    <div className="block-module list-table">
      <ul>
        <li>
          <p>Direction</p>
          <p className="uppercase">{data["position"]}</p>
        </li>
        <li>
          <p>Leverage</p>
          <p>{data["leverage"]}</p>
        </li>
        <li>
          <p>Timeframe</p>
          <p>{data["update_period"]}</p>
        </li>
        <li>
          <p>Last update</p>
          <p>{data["last_update"]}</p>
        </li>
        <li>
          <p>Status</p>
          <p
            className="uppercase"
            style={{ color: `${risk ? "#FF0000" : "#37D159"}` }}
          >
            {data["type"]}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ListTable;
