import { useEffect, useState } from "react";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import HPCard from "../components/HPCard/HPCard";
import Hgroup from "../components/Shared/Hgoup/Hgroup";
import { getApi } from "../lib/druidCall";
import { getUserInfo } from "../lib/loginUtilities";

const Homepage = () => {
  // const [lastUpdateStrategies, setLastUpdateStrategies] = useState(0);
  const [description, setDescription] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [userData] = useState(getUserInfo());

  useEffect(() => {
    let cache =
      JSON.parse(sessionStorage.getItem("strategies_list")) ??
      JSON.stringify([]);
    if (cache) {
      setDescription(cache["description"]);
      setStrategies(cache["strategies"]);
    }
    getApi("strategy_list").then((r) => {
      setDescription(r["description"]);
      setStrategies(r["strategies"]);
      sessionStorage.setItem("strategies_list", JSON.stringify(r));
    });
  }, []);

  return (
    <div className="black-page">
      <Menu pageStyle="black" />
      <div className="homepage-container page-container md:px-11 px-5">
        <Hgroup
          title={`Welcome back<br>${
            userData["displayName"]
              ? userData["displayName"]
              : userData["email"]
          }!`}
          subtitle={description}
        />
        {strategies && strategies.length > 0 ? (
          <div className="md:grid md:grid-cols-12 gap-8 mb-8 overflow-auto customGap">
            {strategies.map((strategy, index) => (
              <HPCard
                key={index}
                color={strategy["color"]}
                title={strategy["name"]}
                description={strategy["description"]}
                drawdown_risk={strategy["drawdown_risk"]}
                link={`/${strategy["sector"] ? "sectors" : "strategy"}/${
                  strategy["code"]
                }`}
              />
            ))}
          </div>
        ) : (
          <div className="animate-pulse md:grid md:grid-cols-12 gap-8 mb-8 overflow-auto customGap">
            <HPCard color="#999999" title="Loading......" />
            <HPCard color="#666666" title="Loading......" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
