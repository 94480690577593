import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer_copyright_area}>
      <div className={styles.footer_content}>
        <div className={styles.footer_grid}>
          <div className={styles.footer_grid_inner}>
            <div className={styles.footer_grid_item}>
              <div className="widget widget_block">
                <a href="https://druid.vision">DRUID.VISION</a>
              </div>
            </div>
            <div className={styles.footer_grid_item}>
              <div className="widget widget_block">
                <p>All right reserved</p>
              </div>
            </div>
            {/*  <div className={styles.footer_grid_item}>*/}
            {/*    <div className="widget widget_block">*/}
            {/*      <a href="https://qodeinteractive.com/">*/}
            {/*        copyright @druid vision Ltd*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*  </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
