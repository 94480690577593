const Hgroup = ({ title, subtitle, h1class }) => {
  return (
    <div className="page-hgroup xl:mb-16 lg:mb-8 md:mb-16 mb-10 grid grid-cols-12">
      <h1
        className={`col-span-12 big-title`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className="xl:text-xl font-medium leading-relaxed col-span-12 lg:col-span-10"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    </div>
  );
};

export default Hgroup;
