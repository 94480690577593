import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { getTrendIcon } from "../../lib/utilitiesComponent";
import { formatPercentage } from "../../lib/utilities";

export default function PieChartSecurity({ data, risk }) {
  const [graph, setGraph] = useState([]);
  const [colors, setColors] = useState(["#B5FF00", "#F0F0F0"]);
  useEffect(() => {
    if (risk) {
      setColors(["#FF0000", "#F0F0F0"]);
    } else {
      setColors(["#B5FF00", "#F0F0F0"]);
    }
  }, [risk]);

  useEffect(() => {
    const last = data["active_securities"].pop();
    const lastObj = [
      {
        name: "active_securities",
        value: last["active_securities"],
      },
      {
        name: "total_securities",
        value: last["total_securities"],
      },
    ];
    setGraph(lastObj);
  }, [data]);

  return (
    <div className="pieContainer">
      <PieChart width={150} height={150}>
        {colors ? (
          <Pie
            data={graph}
            innerRadius={50}
            outerRadius={70}
            startAngle={90}
            endAngle={-270}
            fill="#000000"
            paddingAngle={0}
            dataKey="value"
          >
            {graph.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        ) : (
          ""
        )}
      </PieChart>
      {graph[0] && graph[1] ? (
        <div className="label">
          <span>
            {graph[0]["value"]}/{graph[1]["value"]}
          </span>
          <p>{formatPercentage(data["active_securities_percentage"])}</p>
        </div>
      ) : (
        ""
      )}
      <div className="today">
        {data["active_securities_trend"] === "-" ? (
          <span>Reducing</span>
        ) : data["active_securities_trend"] === "+" ? (
          <span>Increasing</span>
        ) : (
          <span>Remains constant</span>
        )}

        <span>{getTrendIcon(data["active_securities_trend"])}</span>
      </div>
    </div>
  );
}
