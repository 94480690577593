import React, { useEffect, useState } from "react";
import styles from "./securityFooter.module.scss";
import {
  formatPercentage,
  formatPlainPercentage,
} from "../../../lib/utilities";
import { getTrendIcon } from "../../../lib/utilitiesComponent";
import { HashLink } from "react-router-hash-link";

const SecurityFooter = ({ footer, years, security }) => {
  return (
    <>
      {security ? (
        <div className={styles.grid}>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>CAGR</p>
            <p>
              <b className={styles.number}>
                {formatPlainPercentage(security["cagr"])}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Max Drawdown</p>
            <p>
              <b className={styles.number}>
                {formatPlainPercentage(security["max_drawdown"])}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Driver</p>
            <p>
              <b className={`${styles.number} ${styles.smaller}`}>
                {security["driver"]}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Status</p>
            <p>
              <b className={`${styles.number} ${styles.smaller}`}>
                {security["active"] ? "ACTIVE" : "INACTIVE"}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>
              Score %
              <sup>
                <HashLink smooth to="#legenda">
                  5
                </HashLink>
              </sup>
            </p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>
                {formatPercentage(security["score"])}
              </b>
              <span className="trend">
                {getTrendIcon(security["score_trend"])}
              </span>
            </p>
          </div>

          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>
              MA short term
              <sup>
                <HashLink smooth to="#legenda">
                  6
                </HashLink>
              </sup>
            </p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_ma1"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_ma1_trend"])}
              </span>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>
              MA long term
              <sup>
                <HashLink smooth to="#legenda">
                  6
                </HashLink>
              </sup>
            </p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_ma2"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_ma2_trend"])}
              </span>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>
              Slope
              <sup>
                <HashLink smooth to="#legenda">
                  6
                </HashLink>
              </sup>
            </p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_slope"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_slope_trend"])}
              </span>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>
              R^2
              <sup>
                <HashLink smooth to="#legenda">
                  6
                </HashLink>
              </sup>
            </p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_r2"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_r2_trend"])}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.footer_card}>
            <p className={styles.label}>CAGR</p>
            <p>
              <b className={styles.number}>
                {formatPlainPercentage(footer["cagr"][years])}
              </b>
            </p>
          </div>
          <div className={styles.footer_card}>
            <p className={styles.label}>Max Drawdown</p>
            <p>
              <b className={styles.number}>
                {formatPlainPercentage(footer["max_drawdown"][years])}
              </b>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SecurityFooter;
