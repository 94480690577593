import React from "react";
import styles from "./security.module.scss";
import { Link } from "react-router-dom";

const SecurityTableShort = ({ securityList, sectorCode, strategyCode }) => {
  return (
    <>
      <div className={styles.tableShort}>
        <h3>{sectorCode}</h3>
        <ul>
          {securityList.map((value, i) => (
            <li key={i} className={`col-span-2 ${styles.cell}`}>
              <Link
                reloadDocument
                className={styles.security}
                to={`/strategy/${strategyCode}/${sectorCode}/${value["symbol"]}`}
              >
                <span className={styles.symbol}>{value["symbol"]}</span>
                <span className={styles.name}>{value["name"]}</span>
              </Link>
            </li>
          ))}
          {securityList.length % 7 !== 0
            ? [...Array(7 - (securityList.length % 7)).keys()].map(
                (value, i) => (
                  <li key={i} className={`col-span-2 ${styles.cell}`}>
                    &nbsp;
                  </li>
                )
              )
            : ""}
        </ul>
      </div>
    </>
  );
};

export default SecurityTableShort;
