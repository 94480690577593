import React from "react";
import styles from "./security.module.scss";
import SecurityRow from "./SecurityRow";
import { HashLink } from "react-router-hash-link";

const SecurityTable = ({ securityList, sectorCode }) => {
  return (
    <div className={styles.tableWeekly}>
      <div className={styles.tableHeader}>Security</div>
      <div className={styles.tableHeader}>Signal</div>
      <div className={styles.tableHeader}>
        Score
        <sup>
          <HashLink smooth to="#legenda">
            4
          </HashLink>
        </sup>
      </div>
      <div className={styles.tableHeader}>Driver</div>
      <div className={styles.tableHeader}>CAGR</div>
      <div className={styles.tableHeader}>Max Drawdown</div>
      <div className={styles.tableHeader}>Status</div>
      {securityList.map((value, i) => (
        <SecurityRow key={i} security={value} sectorCode={sectorCode} />
      ))}
    </div>
  );
};

export default SecurityTable;
