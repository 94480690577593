import React from "react";
import styles from "./security.module.scss";
import { formatPercentage, formatPlainPercentage } from "../../lib/utilities";
import { getTrendIcon } from "../../lib/utilitiesComponent";
import { Link, useParams } from "react-router-dom";

const SecurityRow = ({ security, sectorCode }) => {
  const { strategyCode } = useParams();

  return (
    <>
      <div className={`2xl:col-span-2 ${styles.cell}`}>
        <Link
          className={styles.security}
          to={`/strategy/${strategyCode}/${sectorCode}/${security["symbol"]}`}
        >
          <span className={styles.symbol}>{security["symbol"]}</span>
          <span className={styles.name}>{security["name"]}</span>
          <span className={styles.sector}>{security["sector"]}</span>
        </Link>
      </div>

      <div className={`${styles.cell} ${styles.signalCell}`}>
        <span>{security["signal"]}</span>
      </div>

      <div className={`${styles.cell} ${styles.scoreCell}`}>
        <span>{formatPercentage(security["score"])}</span>
        <span className={`${styles.trend}`}>
          {getTrendIcon(security["score_trend"])}
        </span>
      </div>

      <div className={styles.cell}>
        <span>{security["driver"]}</span>
      </div>

      <div className={styles.cell}>
        <span>{formatPlainPercentage(security["cagr"])}</span>
      </div>

      <div className={styles.cell}>
        <span>{formatPlainPercentage(security["max_drawdown"])}</span>
      </div>

      <div className={styles.cell}>
        <span>{security["active"] ? "ACTIVE" : "INACTIVE"}</span>
      </div>
    </>
  );
};

export default SecurityRow;
