import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Footer from "../components/Shared/Footer/Footer";
import SecurityTable from "../components/Security/SecurityTable";
import SectorsList from "../components/SectorsList/SectorsList";
import BigHeader from "../components/Shared/Header/BigHeader";

const Sector = () => {
  const { strategyCode } = useParams();
  const { sectorCode } = useParams();
  const [strategy, setStrategy] = useState({});
  const [sectors, setSectors] = useState([]);
  const [securities, setSecurities] = useState([]);

  useEffect(() => {
    getApi("strategy_details", { strategy: strategyCode }).then((r) => {
      setStrategy(r["info"]);
      sessionStorage.setItem(
        strategyCode + "_strategy",
        JSON.stringify(r["info"])
      );
    });

    getApi("sector_list", { strategy: strategyCode }).then((r) => {
      setSectors(r);
      sessionStorage.setItem(strategyCode + "_sector_list", JSON.stringify(r));
    });
  }, [strategyCode]);

  useEffect(() => {
    const strategy = JSON.parse(
      sessionStorage.getItem(strategyCode + "_strategy")
    );
    const sectors = JSON.parse(
      sessionStorage.getItem(strategyCode + "_sector_list")
    );
    const securities = JSON.parse(
      sessionStorage.getItem(strategyCode + "_" + sectorCode + "_security_list")
    );
    if (strategy) {
      setStrategy(strategy);
    }
    if (sectors) {
      setSectors(sectors);
    }
    if (securities) {
      setSecurities(securities);
    }

    getApi("security_list", {
      strategy: strategyCode,
      sector: sectorCode,
    }).then((r) => {
      setSecurities(r);
      sessionStorage.setItem(
        strategyCode + "_" + sectorCode + "_security_list",
        JSON.stringify(r)
      );
    });
  }, [strategyCode, sectorCode]);

  return (
    <>
      <BigHeader strategy={strategy} />
      {strategy["color"] ? (
        <>
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to={"/"}>Index</Link>
              </li>
              <li>
                <Link to={`/strategy/${strategyCode}`}>{strategy["name"]}</Link>
              </li>
              <li>{sectorCode}</li>
            </ul>
          </section>

          <div
            className="sectorTitle"
            style={{ backgroundColor: `${strategy["color"]}` }}
          >
            {sectorCode}
          </div>
        </>
      ) : null}

      {securities.length ? (
        <SecurityTable securityList={securities} sectorCode={sectorCode} />
      ) : (
        <div className="animate-pulse bodyPlaceholder">
          <h2>
            <span className="loader">Loading </span>
            <p>{sectorCode}</p>
          </h2>
        </div>
      )}

      {sectors.length > 0 ? (
        <SectorsList strategyCode={strategyCode} sectors={sectors} />
      ) : (
        ""
      )}

      <section id="legenda">
        <p className="label">
          Here we provide a legend explaining the meaning of the data presented
        </p>
        <div className="note-content">
          {strategy ? (
            <div className="note">
              <b>1. {strategy["name"]}</b>
              <p>
                The execution of this strategy follows these parameters: entry
                and exit for each security occur at the open price unless a stop
                loss equal to 3% from the average purchase price is triggered.
                In the case of open gaps exceeding 3%, the trade is entirely or
                partially skipped. Druid Vision remains available to analyze,
                implement, and optimize both discretionary and systematic
                execution.
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="note">
            <b>2. Active Stocks</b>
            <p>
              An active stock is one where a group of AI-enhanced algorithms has
              consistently identified patterns that can be positively leveraged
              for performance. This graph shows the trend in the proportion of
              active stocks within the total reference universe, highlighting
              how the number of stocks with exploitable patterns has evolved
              over time
            </p>
          </div>
          <div className="note">
            <b>3. Drawdown risk</b>
            <p>
              Drawdown risk represents the potential decline in investment value
              from its peak to a lower point. Our goal is to avoid significant
              drawdowns, aiming to maintain stability in returns. The trend in
              active stocks can impact this risk level, ranging from very low to
              extreme. By carefully analyzing active stock patterns, we can
              manage and mitigate exposure to higher drawdown risks.
            </p>
          </div>
          <div className="note">
            <b>4. Score</b>
            <p>
              The Score represents an effectiveness level, ranging from 0 to
              100%, of the best AI-powered algorithm selected for a given stock.
              The Driver refers to the specific market condition that the
              algorithm identifies and exploits to achieve optimal performance.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Sector;
