import Menu from "../Menu/Menu";
import Header from "./Header";
import ListTable from "../../HeaderBlocks/ListTable";
import BarGraph from "../../HeaderBlocks/BarGraph";
import LineGraph from "../../HeaderBlocks/LineGraph";
import { useEffect, useState } from "react";

const BigHeader = ({ strategy }) => {
  const [color, setColor] = useState("#333333");
  const [risk, setRisk] = useState(false);
  useEffect(() => {
    if (strategy && strategy["drawdown_risk"] > 1) {
      setColor(strategy["color"]);
      setRisk(false);
    } else {
      setColor("#CACACA");
      setRisk(true);
    }
  }, [strategy]);

  return (
    <>
      {strategy && strategy["color"] ? (
        <>
          <Menu background={color} />
          <Header data={strategy} risk={risk} />
          <section className="block-row">
            <ListTable data={strategy} risk={risk} />
            <div className="second-row">
              <BarGraph data={strategy} risk={risk} />
              <LineGraph data={strategy} />
            </div>
          </section>
        </>
      ) : (
        <div className="animate-pulse">
          <Menu background={"#333333"} />
          <Header
            data={{ color: "#333333", name: "...", description: "..." }}
          />
        </div>
      )}
    </>
  );
};

export default BigHeader;
