import React from "react";
import Equal from "../images/icons/equal.svg";
import Plus from "../images/icons/plus.svg";
import Minus from "../images/icons/minus.svg";

export const getTrendIcon = (trend) => {
  return trend === "=" ? (
    <img className="ml-3" src={Equal} alt="Equal" />
  ) : trend === "+" ? (
    <img className="ml-3" src={Plus} alt="Plus" />
  ) : trend === "-" ? (
    <img className="ml-3" src={Minus} alt="Minus" />
  ) : (
    ""
  );
};
