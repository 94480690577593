import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/globals.scss";
import reportWebVitals from "./reportWebVitals";
import Strategy from "./pages/strategy";
import Login from "./pages/login";
import Page404 from "./pages/page404";
import Sector from "./pages/sector";
import Security from "./pages/security";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/strategy/:strategyCode" element={<Strategy />} />
        <Route
          path="/strategy/:strategyCode/:sectorCode"
          element={<Sector />}
        />
        <Route
          path="/strategy/:strategyCode/:sectorCode/:securityCode"
          element={<Security />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
