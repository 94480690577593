import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <>
      <Menu />
      <main className="homepage-container page-container w-full flex flex-col justify-center items-center">
        <h1 className="text-9xl font-extrabold text-white tracking-widest">
          404
        </h1>
        <div className="bg-[#F6C289] px-2 text-sm text-[#37859B] rounded rotate-12 absolute">
          Page Not Found
        </div>
        <button className="mt-5">
          <a className="relative inline-block text-sm font-medium text-[#37859B] group active:text-orange-500 focus:outline-none focus:ring">
            <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#37859B] group-hover:translate-y-0 group-hover:translate-x-0"></span>

            <span className="relative block px-8 py-3 bg-[#F6C289] border border-current">
              <Link to="/">Go Home</Link>
            </span>
          </a>
        </button>
      </main>
      <Footer />
    </>
  );
};

export default Page404;
