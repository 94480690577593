import { colouredBar, drawdownRiskTable } from "../../lib/utilities";
import { HashLink } from "react-router-hash-link";

const LineGraph = ({ data }) => {
  const drawdown =
    data["cumulated_returns"]["all"][
      data["cumulated_returns"]["all"].length - 1
    ]["drawdown_risk"];

  return (
    <div className="block-module bar-graph">
      <h2>
        Drawdown Risk
        <sup>
          <HashLink smooth to="#legenda">
            3
          </HashLink>
        </sup>
      </h2>
      <div>
        <p
          className="signal"
          style={{ color: drawdownRiskTable(drawdown, "color") }}
        >
          {drawdownRiskTable(drawdown, "label")}
        </p>
        <ul className="bar">
          <li style={{ backgroundColor: colouredBar(drawdown, 1) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 2) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 3) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 4) }}></li>
          <li style={{ backgroundColor: colouredBar(drawdown, 5) }}></li>
        </ul>
        <p className="legend">
          Drawdown risk represents the potential decline in investment value.
          Our goal is to avoid significant drawdowns, aiming to maintain
          stability in returns. The trend in active stocks can impact this risk
          level, ranging from very low to extreme.{" "}
          <HashLink smooth to="#legenda">
            Read more
          </HashLink>
        </p>
      </div>
    </div>
  );
};

export default LineGraph;
